import { z } from 'zod';
import { IEmployee } from '@/types.ts';
import { Button } from '@/components/ui/button.tsx';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from '@/components/ui/form.tsx';
import { sdk } from '@/api.ts';
import { formatDateIfExists } from '@/routes/dashboard/administrators/shared.tsx';
import { FormTextField } from '@/components/text-field.tsx';

interface EditEmployeeFormProps {
  employee: IEmployee | null;
  handleCancel: () => void;
  handleSuccess: () => void;
}

const formSchema = z.object({
  first_name: z.union([z.string(), z.literal('')]),
  last_name: z.union([z.string(), z.literal('')]),
  email: z.string().email({ message: 'Invalid email address' }),
});

const EditAdministratorForm = ({
  employee,
  handleCancel,
  handleSuccess,
}: EditEmployeeFormProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: employee?.first_name ?? '',
      last_name: employee?.last_name ?? '',
      email: employee?.email,
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    sdk
      .updateEmployee({
        ...data,
        id: employee?.id,
      })
      .then(() => {
        handleSuccess();
      })
      .catch(() => {
        // @TODO how to error here?
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex">
          <div className="flex-1 flex flex-col gap-y-2">
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField
                      label="First Name"
                      test-id="first-name-input"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField
                      label="Last Name"
                      test-id="last-name-input"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <FormTextField label="Email" type="email" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="flex-1 flex justify-end pr-8">
            <div>
              <dl className="detail-dl">
                <dt>Invitation Date</dt>
                <dd>{formatDateIfExists(employee?.invite_created_at)}</dd>
                <dt>Registration Date</dt>
                <dd>{formatDateIfExists(employee?.created_at)}</dd>
                <dt>Last Login</dt>
                <dd>{formatDateIfExists(employee?.last_login_timestamp)}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-[-1rem] flex gap-x-2">
          <Button type="submit">Save</Button>
          <Button variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default EditAdministratorForm;
