import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form.tsx';
import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';

interface TermsConditionsProps {
  loading: boolean;
  onSubmit: () => void;
}

const FormSchema = z.object({
  termsAccepted: z.boolean().default(false),
});

const TermsConditions = ({ loading, onSubmit }: TermsConditionsProps) => {
  const [termsContent, setTermsContent] = useState<string>('');

  useEffect(() => {
    sdk.getTerms().then((response) => {
      setTermsContent(response.data);
    });
  }, []);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      termsAccepted: false,
    },
  });

  const isChecked = form.watch('termsAccepted').valueOf();

  return (
    <div className="mx-auto p-4">
      <div className="max-w-[640px]">
        <div
          className="markdown"
          dangerouslySetInnerHTML={{ __html: termsContent }}
        ></div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="termsAccepted"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>
                      I have read and agree to these terms and conditions.
                    </FormLabel>
                  </div>
                </FormItem>
              )}
            />
            <Button loading={loading} disabled={!isChecked} type="submit">
              Submit
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default TermsConditions;
