import face from '@/assets/images/face.svg';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';
import ChatRecommendation from '@/components/chat/chat-recommendation.tsx';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';
import rehypeRaw from 'rehype-raw'; // Import rehypeRaw
import { defaultSchema } from 'hast-util-sanitize'; // Import defaultSchema

// Extend the default schema
const sanitizeSchema = {
  ...defaultSchema,
  attributes: {
    // Use nullish coalescing to default to an empty object if undefined or null
    ...(defaultSchema.attributes ?? {}),
    a: [
      // Use optional chaining and nullish coalescing to handle undefined or null
      ...(defaultSchema.attributes?.a ?? []),
      'target',
      'rel',
    ],
  },
};

function getParsedMessage(message: string) {
  let containsBraces = false;
  let recommendationName = '';
  const parsedMessage = message.replace(/\{\{([^}]+)}}/g, (_, matchedGroup) => {
    containsBraces = true;
    const [name] = matchedGroup.split(':');
    recommendationName = name.trim();
    return name.trim();
  });

  return { parsedMessage, recommendationName, containsBraces };
}

export default function ChatResponse({
  type,
  message,
  onClickRecommendation,
  loading = false,
}: {
  message: string;
  loading?: boolean;
  onClickRecommendation?: () => void;
  type: 'user' | 'bot';
}) {
  const baseStyles = 'chat-response p-4 rounded-lg text-dark font-medium';
  if (type === 'user') {
    return (
      <div className={`${baseStyles} bg-white`}>
        <div>{message}</div>
      </div>
    );
  }

  const { parsedMessage, recommendationName, containsBraces } = getParsedMessage(message);

  return (
    <div className={`${baseStyles} flex items-start`}>
      <img className="mr-4" src={face} alt="chat user" />
      {loading && !message ? (
        <LoadingSpinner className="mt-1" color="hsl(var(--input-foreground))" />
      ) : (
        <div className="flex flex-col gap-2">
          <ReactMarkdown
            className="markdown"
            rehypePlugins={[rehypeRaw, [rehypeSanitize, sanitizeSchema]]} // Use rehypeRaw and custom schema
          >
            {parsedMessage}
          </ReactMarkdown>
          {containsBraces && (
            <ChatRecommendation
              name={recommendationName}
              description="This is a recommendation description."
              onClick={onClickRecommendation!}
            />
          )}
        </div>
      )}
    </div>
  );
}