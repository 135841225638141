import { useState } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { BiEnvelope } from 'react-icons/bi';
import { sdk } from '@/api.ts';
import { IInvite } from '@/types.ts';
import { useInvitesStore } from '@/routes/dashboard/invites/store.ts';

interface ResendInviteProps {
  invite: IInvite;
}

const ResendInvite = ({ invite }: ResendInviteProps) => {
  const [loading, setLoading] = useState(false);
  const fetchInvites = useInvitesStore((state) => state.fetchInvites);

  const doResend = () => {
    setLoading(true);
    const requestData = [invite];
    sdk
      .resendInvites(requestData)
      .then(() => {
        fetchInvites();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <div className="flex justify-center items-center">
      <Button variant="link" onClick={doResend} loading={loading}>
        <BiEnvelope size={20} />
      </Button>
    </div>
  );
};

export default ResendInvite;
