import { Card } from '@/components/ui/card.tsx';
import { Link } from 'react-router-dom';
import { useAuth } from '@/providers/auth.tsx';
import { BiSolidChat, BiSolidDashboard } from 'react-icons/bi';

const ExperienceSwitcher = () => {
  const { user } = useAuth();
  return (
    <div className="flex justify-center h-full items-center p-4">
      <div className="w-[480px] flex flex-col gap-y-4">
        <h1 className="text-lg font-semibold">Select an account to view:</h1>
        <div className="flex flex-col gap-y-4">
          <Link
            className="flex-1"
            onClick={() =>
              localStorage.setItem('experiencePreference', 'dashboard')
            }
            to="/dashboard"
          >
            <Card className="flex items-center gap-x-2 hover:border-primary">
              <BiSolidDashboard />
              <p>{user.organization_name} Sponsor Account</p>
            </Card>
          </Link>
          <Link
            className="flex-1"
            onClick={() => localStorage.setItem('experiencePreference', 'chat')}
            to="/app"
          >
            <Card className="flex items-center gap-x-2 hover:border-primary">
              <BiSolidChat />
              <p>
                {user.first_name} {user.last_name}&apos;s Participant Account
              </p>
            </Card>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExperienceSwitcher;
