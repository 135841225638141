import { IUser } from '@/types.ts';
import { convertDateStringFormat } from '@/lib/utils.ts';
export default function AccountPersonalDetails({
  user,
  maritalStatuses,
}: {
  user: IUser;
  maritalStatuses: Record<string, string>;
}) {
  const salary = user.user_finance?.find((fin) => {
    return fin.finance_category === 'annual_salary';
  }) || { amount: 'N/A' };

  return (
    <div className="flex flex-col gap-y-2 mt-4 text-sm">
      <div>
        <p className="font-bold">Name</p>
        <p test-id="personal-full-name">
          {user.first_name} {user.last_name}
        </p>
      </div>
      <div>
        <p className="font-bold">Date of Birth</p>
        <p test-id="personal-dob">
          {convertDateStringFormat(user.user_detail?.date_of_birth as string) ||
            'N/A'}
        </p>
      </div>
      <div>
        <p className="font-bold">Marital Status</p>
        <p test-id="personal-marital-status">
          {maritalStatuses[user.user_detail?.marital_status_id as string] ||
            'N/A'}
        </p>
      </div>
      <div>
        <p className="font-bold">Employer</p>
        <p test-id="personal-employer">{user.organization_name}</p>
      </div>
      <div>
        <p className="font-bold">Annual Salary</p>
        <p test-id="personal-annual-salary">{salary.amount}</p>
      </div>
    </div>
  );
}
