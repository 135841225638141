import { SyntheticEvent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { DataTable } from '@/components/data-table.tsx';
import { columns } from '@/routes/dashboard/invites/columns.tsx';
import { Card } from '@/components/ui/card.tsx';
import InviteForm from '@/routes/dashboard/invites/invite-form.tsx';
import { BiChevronLeft, BiChevronRight, BiEnvelope } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useInvitesStore } from '@/routes/dashboard/invites/store.ts';

const Invites = () => {
  const [addInviteModalOpen, setAddInviteModalOpen] = useState(false);
  const invites = useInvitesStore((state) => state.invites);
  const pagination = useInvitesStore((state) => state.pagination);
  const handlePageChange = useInvitesStore((state) => state.handlePageChange);
  const fetchInvites = useInvitesStore((state) => state.fetchInvites);

  useEffect(() => {
    fetchInvites(pagination.page);
  }, [pagination.page]);

  return (
    <div>
      <Dialog open={addInviteModalOpen} onOpenChange={setAddInviteModalOpen}>
        <DialogContent>
          <DialogTitle>Invite new user</DialogTitle>
          <DialogDescription className="hidden">
            Invite new user
          </DialogDescription>
          <InviteForm onSuccess={fetchInvites} />
        </DialogContent>
      </Dialog>
      <h1 className="text-2xl flex relative items-center font-semibold">
        <BiEnvelope size={32} className="mr-2" />
        Invites
      </h1>
      <div className="flex justify-between items-center">
        <Link to="/dashboard" className="flex items-center mt-2 underline">
          <BiChevronLeft className="mr-2" size="24" />
          Back to Dashboard
        </Link>
      </div>
      <div className="flex justify-between items-center">
        <form
          className="flex max-w-xs mt-4"
          onSubmit={(e) => {
            e.preventDefault();
            fetchInvites();
          }}
        >
          <Input
            placeholder="Search by email"
            onChange={(e: SyntheticEvent) =>
              useInvitesStore.setState({
                searchQuery: (e.target as HTMLInputElement).value,
              })
            }
          />
          <Button type="submit" variant="secondary" className="ml-2">
            <BiChevronRight size={22} />
          </Button>
        </form>
        <Button onClick={() => setAddInviteModalOpen(true)}>
          Invite Users
        </Button>
      </div>
      <Card className="mt-4">
        <DataTable
          pagination={pagination}
          onPageChange={handlePageChange}
          columns={columns}
          data={invites}
        />
      </Card>
    </div>
  );
};

export default Invites;
