import './index.css';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from './providers/auth.tsx';
import { StrictMode } from 'react';
import router from '@/router.tsx';

const environment = import.meta.env.VITE_ENV || 'localhost';
// The DSN is currently only provided on HEROKU for the staging and prod envs
// Not providing a DSN limits the local and dev environments from accessing sentry
// (we only want this on staging and prod, as staging is a demo env and prod is...prod)
// For more info, see this comment:
// https://github.com/getsentry/sentry-javascript/discussions/8546#discussioncomment-6556295
const dsn = import.meta.env.VITE_SENTRY_DSN;

Sentry.init({
  dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment,
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </StrictMode>
);
