import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form.tsx';

import { IUser } from '@/types.ts';
import { Button } from '@/components/ui/button.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  convertStringToDate,
  convertDateStringFormat,
  dateRegex,
} from '@/lib/utils.ts';
import { useState } from 'react';
import { sdk } from '@/api.ts';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';
import { useAuth } from '@/providers/auth.tsx';
import { FormTextField } from '@/components/text-field.tsx';

const formSchema = z.object({
  first_name: z.string().min(1, 'First name is required'),
  last_name: z.string().min(1, 'Last name is required'),
  preferred_name: z.string().optional(),
  date_of_birth: z
    .string()
    .regex(dateRegex, 'Invalid date format. Use MM/DD/YYYY')
    .optional()
    .or(z.literal('')),
  marital_status: z.string().optional(),
  annual_salary: z
    .number()
    .int()
    .positive()
    .min(1)
    .optional()
    .or(z.literal(null)),
});

export default function EditPersonalDetailsForm({
  user,
  maritalStatuses,
}: {
  user: IUser;
  maritalStatuses: Record<string, string>;
}) {
  const { refreshUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // Need to dig the salary out of the user_finance array
  const salary = user.user_finance?.find(
    (finance) => finance.finance_category === 'annual_salary'
  ) || { amount: null };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      preferred_name: user.preferred_name ?? '',
      date_of_birth: convertDateStringFormat(
        user.user_detail?.date_of_birth as string
      ),
      marital_status: user.user_detail?.marital_status_id ?? '',
      annual_salary: salary.amount,
    },
  });

  // @TODO inner logic here needs to probably be broken out and unit tested
  const onSubmit = (formValues: z.infer<typeof formSchema>) => {
    setLoading(true);
    // If user has an existing user_finance object for salary, we need to update
    // it as opposed to creating a new one
    const existingUserSalaryObject = user.user_finance?.find(
      (finance) => finance.finance_category === 'annual_salary'
    );

    // Building the user_finance object for salary; If the user has an existing
    // salary object, we need to include the ID and user_id in the object
    const userSalaryFinanceObject = {
      finance_category: 'annual_salary',
      amount: formValues.annual_salary,
      ...(existingUserSalaryObject && {
        id: existingUserSalaryObject.id,
        user_id: user.id,
      }),
    };

    const updatedUserDetail = {
      // Only send date_of_birth if there is a value to update
      ...(formValues.date_of_birth && {
        date_of_birth: convertStringToDate(formValues.date_of_birth),
      }),
      // Only send marital_status_id if there is a value to update
      ...(formValues.marital_status && {
        marital_status_id: formValues.marital_status,
      }),
    };

    const updatedUserFinance = [userSalaryFinanceObject];

    const formData = {
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      preferred_name: formValues.preferred_name,
      // Only send user_detail if there are values to update
      ...((formValues.date_of_birth || formValues.marital_status) && {
        user_detail: updatedUserDetail,
      }),
      // Only send user_finance if there are values to update
      ...(formValues.annual_salary && { user_finance: updatedUserFinance }),
    };

    sdk
      .updateUser(formData)
      .then(() => {
        refreshUser();
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <FormField
          control={form.control}
          name="first_name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  label="First Name"
                  test-id="personal-first-name-input"
                  error={form.formState.errors.first_name?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="last_name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="personal-last-name-input"
                  label="Last Name"
                  error={form.formState.errors.last_name?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="preferred_name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="personal-preferred-name-input"
                  label="Preferred Name"
                  error={form.formState.errors.preferred_name?.message}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="date_of_birth"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="personal-dob-input"
                  label="Date of Birth"
                  error={form.formState.errors.date_of_birth?.message}
                  helperText="MM/DD/YYYY"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          name="marital_status"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Marital Status</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger test-id="user-marital-status-dropdown">
                    <SelectValue placeholder="Marital Status" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Object.entries(maritalStatuses).map(([key, value]) => (
                    <SelectItem key={key} value={key}>
                      {value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="annual_salary"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTextField
                  test-id="personal-annual-salary-input"
                  type="number"
                  label="Annual Salary"
                  error={form.formState.errors.annual_salary?.message}
                  {...field}
                  value={field.value === null ? '' : field.value}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value === '' ? null : parseFloat(e.target.value)
                    )
                  }
                />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="flex gap-x-4 items-center">
          <Button disabled={loading} type="submit">
            {loading ? <LoadingSpinner /> : 'Save'}
          </Button>
          <div>
            {error && <p className="text-destructive">Something went wrong.</p>}
            {success && <p>Information updated.</p>}
          </div>
        </div>
      </form>
    </Form>
  );
}
