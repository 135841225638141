import AccountLayout from '@/routes/account/layout.tsx';
import { Button } from '@/components/ui/button.tsx';
import { ReactNode } from 'react';
import { Card } from '@/components/ui/card.tsx';

const ProfileBlock = ({
  title,
  subtitle,
  children,
  buttonLabel = 'Delete',
}: {
  title: string;
  subtitle: string;
  children: ReactNode;
  buttonLabel?: 'Restrict' | 'Delete';
}) => {
  return (
    <div className="mb-8 text-sm">
      <div className="flex gap-x-4 justify-between">
        <div>
          <h3 className="font-bold">{title}</h3>
          <p className="italic">{subtitle}</p>
        </div>
        <Button size="xs" className="bg-purple rounded-full">
          {buttonLabel}
        </Button>
      </div>
      <div className="py-4">{children}</div>
    </div>
  );
};

export default function Profile() {
  return (
    <AccountLayout>
      <Card>
        <div className="mb-12">
          <div className="flex justify-between mb-4 items-center">
            <h2 className="text-lg font-bold">
              Personal &amp; Financial Profile
            </h2>
            <Button size="xs" className="bg-purple rounded-full">
              Wipe all my data
            </Button>
          </div>
          <p className="text-sm max-w-[65%]">
            Peopled gathers personal and financial information to tailor our
            responses to your unique situation. You’re always in control of your
            data—we’ve made it easy for you to view, edit, restrict, or delete
            your information anytime you want.
          </p>
        </div>
        <div className="mt-4">
          <div className="flex justify-between gap-x-8">
            <div>
              <ProfileBlock
                title="Employment Information"
                subtitle="Provided by your employer"
                buttonLabel="Restrict"
              >
                <ul>
                  <li className="flex justify-between">
                    <span>Job Title</span>
                    <span>Police Detective</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Years Employed</span>
                    <span>9 years</span>
                  </li>
                </ul>
              </ProfileBlock>
              <ProfileBlock
                title="Benefits Information"
                subtitle="Provided by your employer"
                buttonLabel="Restrict"
              >
                <p>Benefits Enrolled In:</p>
                <p>Kaiser Foundation Plan (+ Vision) Delta</p>
                <p>
                  Dental Short-Term Disability Long-Term Disability Accidental
                </p>
                <p>Death and Dismemberment Sunnydale City Workers Pension</p>
                <p className="mt-4">Benefits Currently Not Enrolled In:</p>
                <p>Health Savings Account (HSA)</p>
              </ProfileBlock>
              <ProfileBlock
                title="Assets &amp; Liabilities"
                subtitle="Provided by you in the chat"
              >
                <p>Tangible Property:</p>
                <ul>
                  <li className="flex justify-between">
                    <span>Home</span>
                    <span>$1,700,000</span>
                  </li>
                </ul>
              </ProfileBlock>
            </div>
            <div>
              <ProfileBlock
                title="Personal Income"
                subtitle="Provided by you in the chat"
              >
                <ul>
                  <li className="flex justify-between">
                    <span>Annual Salary</span>
                    <span>$8,000</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Investment Income</span>
                    <span>$0</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Other Income</span>
                    <span>$1,000</span>
                  </li>
                  <li className="flex justify-between my-4">
                    <span>Total Monthly Income</span>
                    <span>$9,000</span>
                  </li>
                  <p>Monthly Expenditures</p>
                  <li className="flex justify-between">
                    <span>Taxes</span>
                    <span>$3,100</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Mortgage Payments</span>
                    <span>$2,000</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Loan Payments</span>
                    <span>$1,000</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Living Expenses</span>
                    <span>$2,000</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Insurance Premiums</span>
                    <span>$200</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Entertainment &amp; Travel</span>
                    <span>$400</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Other Expenses</span>
                    <span>$300</span>
                  </li>
                  <li className="flex justify-between my-4">
                    <span>Total Monthly Expenditures</span>
                    <span>$9,000</span>
                  </li>
                  <li className="flex justify-between mt-4">
                    <span>Discretionary (or Net) Income</span>
                    <span>$500</span>
                  </li>
                </ul>
              </ProfileBlock>
              <ProfileBlock
                title="Non-Financial Considerations"
                subtitle="Provided by you in the chat"
              >
                <ul>
                  <li className="flex justify-between">
                    <span>Age</span>
                    <span>43</span>
                  </li>
                  <li className="flex justify-between">
                    <span>Retirement Horizon</span>
                    <span>24 Years</span>
                  </li>
                </ul>
              </ProfileBlock>
            </div>
          </div>
        </div>
      </Card>
    </AccountLayout>
  );
}
