import { BiInfoCircle } from 'react-icons/bi';

interface BannerProps {
  description: string;
  title: string;
  variant?: 'warning'; // Possible future variants: 'info' | 'error'
}

const Banner = ({ description, title, variant = 'warning' }: BannerProps) => {
  let variableIcon;

  // creating a switch for potential future states
  switch (variant) {
    case 'warning':
      variableIcon = <BiInfoCircle size={24} />;
      break;
    default:
      variableIcon = <BiInfoCircle size={24} />;
  }
  return (
    <div className="flex gap-x-2 border rounded p-4 bg-background">
      <div>{variableIcon}</div>
      <div>
        <h3 className="font-semibold">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Banner;
