import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import API, { sdk } from '../api';
import { fetchAndSetTheme, resetTheme } from '@/lib/theme.ts';

type AuthState = {
  user: any;
  loading: boolean;
  login: (_email: string, _password: string) => void;
  loginOTP: (_email: string, _passcode: string) => void;
  logout: () => void;
  refreshUser: () => void;
};

const AuthContext = createContext<AuthState>({
  user: null,
  loading: true,
  login: (_username: string, _password: string) => '',
  loginOTP: (_email: string, _passcode: string) => '',
  logout: () => '',
  refreshUser: () => '',
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await fetchAndSetTheme();
        const response = await API.get('/users/me');
        setUser(response.data);
      } catch (error) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  // After user update, refresh user data in context/store
  const refreshUser = async () => {
    try {
      const response = await API.get('/users/me');
      setUser(response.data);
    } catch (error) {
      setUser(null);
    }
  };

  const login = async (email: string, password: string) => {
    try {
      // Initial login to get CSRF token
      const response = await API.post('/auth/login', {
        email,
        password,
      });

      // Setting local storage for CSRF double submit token (maintainer of package approves)
      localStorage.setItem(
        'csrf_access_token',
        response.data.csrf_access_token
      );

      // Getting initial user data
      const user = await API.get('/users/me', {
        headers: {
          'X-CSRF-TOKEN': response.data.csrf_access_token,
        },
      });
      setUser(user);
      window.location.replace('/app');
    } catch (error) {
      // @TODO login failure error case? How would we handle this?
      throw error;
    }
  };

  const loginOTP = async (email: string, passcode: string) => {
    try {
      // Initial login to get CSRF token
      const response = await sdk.submitOTPCode(email, passcode);

      // Setting local storage for CSRF double submit token (maintainer of package approves)
      localStorage.setItem('csrf_access_token', response.csrf_access_token);

      // Getting initial user data
      const user = await API.get('/users/me', {
        headers: {
          'X-CSRF-TOKEN': response.csrf_access_token,
        },
      });
      setUser(user);
      window.location.replace('/app');
    } catch (error) {
      // @TODO login failure error case? How would we handle this?
      throw error;
    }
  };

  const logout = async () => {
    try {
      resetTheme();
      await API.post('/auth/logout');
      // @TODO is there anything we'd like to keep in storage? Or should we just
      // @TODO clear everything on logout?
      localStorage.removeItem('csrf_access_token');
      localStorage.removeItem('experiencePreference');
      setUser(null);
    } catch (error) {
      // @TODO logout failure error case? How would we handle this?
      console.error('Logout failed:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, loading, login, loginOTP, logout, refreshUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
