import { sdk } from '@/api.ts';

type ThemeColors = {
  primary: string;
  'app-background': string;
  linear1: string;
  linear2: string;
  linear3: string;
};

type ThemeImages = {
  logo: string | null;
  'logo-secondary': string | null;
};

interface ITheme {
  colors: ThemeColors;
  images: ThemeImages;
}

// Hardcoded colors for the original Peopled 'theme'. No real way to get these
// from our CSS file that I'm aware of, so just hardcoding them here.
const originalTheme: ITheme = {
  colors: {
    primary: '#31214C',
    'app-background': '#FAFAFA',
    linear1: '#ce67a0',
    linear2: '#dd7a62',
    linear3: '#c1c770',
  },
  images: {
    logo: null,
    'logo-secondary': null,
  },
};

/**
 * filterEmptyValues() filters out all empty values from an object.
 * Takes a theme object and removes any null or empty string values from it
 * @param obj
 * @returns A new ITheme partial object with all empty values removed.
 */
const filterEmptyValues = (obj: ITheme): Partial<ITheme> =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([_key, value]) => value !== null && value !== ''
    )
  );

/**
 * setTheme() sets the theme in localStorage and updates the CSS variables.
 * @param theme - The theme object to set in localStorage.
 * Initially sets the original theme in localStorage for later retrieval on reset, then sets the new theme.
 */
export const setTheme = (theme: any) => {
  // Stripping out all empty values from the theme object before saving it to localStorage.
  const filteredTheme = {
    ...theme,
    ...(theme.colors && { colors: filterEmptyValues(theme.colors) }),
    ...(theme.images && { images: filterEmptyValues(theme.images) }),
  };

  // Setting the filtered theme in localStorage
  localStorage.setItem('theme', JSON.stringify(filteredTheme));

  // Applying all the new colors from the theme to the CSS variables
  const root = document.documentElement;

  for (const key in filteredTheme.colors) {
    root.style.setProperty(`--${key}`, hexToHSL(filteredTheme.colors[key]));
  }
};

/**
 * fetchAndSetTheme() fetches the theme from the API and sets it in localStorage.
 */
export const fetchAndSetTheme = async () => {
  return sdk.getTheme().then((response) => {
    setTheme(response.data);
  });
};

/**
 * resetTheme() resets the theme in localStorage to the original theme.
 */
export const resetTheme = () => {
  localStorage.removeItem('theme');
  setTheme(originalTheme);
};

/**
 * getTheme() retrieves the theme from localStorage and returns it as a javascript object.
 */
export const getTheme = () => {
  const theme = localStorage.getItem('theme');
  const parsedTheme = theme ? JSON.parse(theme) : { images: null };
  let logoUrl;
  if (parsedTheme && parsedTheme.images?.logo) {
    logoUrl = parsedTheme.images.logo;
  }

  return {
    ...parsedTheme,
    ...(logoUrl && { logoUrl }),
  };
};

function hexToHSL(hex: string) {
  // Convert hex to RGB first
  let r: any = 0,
    g: any = 0,
    b: any = 0;
  if (hex.length == 4) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];
  } else if (hex.length == 7) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin;

  let h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return h + ',' + s + '%,' + l + '%';
}
