import { BiUser } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Input } from '@/components/ui/input.tsx';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button.tsx';
import {
  EmployeeStatus,
  useEmployeesStore,
} from '@/routes/dashboard/employees/store.ts';
import { Card } from '@/components/ui/card.tsx';
import { DataTable } from '@/components/data-table.tsx';
import { columns } from '@/routes/dashboard/employees/columns.tsx';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import AddEmployeeForm from '@/routes/dashboard/employees/add-employee-form.tsx';
import { IEmployee, IInvite } from '@/types.ts';
import EmployeeDetail from '@/routes/dashboard/employees/employee-detail.tsx';
import EditEmployeeForm from '@/routes/dashboard/employees/edit-employee-form';
import { sdk } from '@/api.ts';

const Employees = () => {
  const {
    employees,
    searchQuery,
    statusFilter,
    pagination,
    handlePageChange,
    fetchEmployees,
  } = useEmployeesStore();

  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showEditEmployee, setShowEditEmployee] = useState(false);
  const [reinviteLoading, setReinviteLoading] = useState(false);
  const [reInviteStatusMessage, setReInviteStatusMessage] = useState('');
  const [editable, setEditable] = useState(false);
  const [editableEmployee, setEditableEmployee] = useState<IEmployee | null>(
    null
  );

  const showReInviteButton =
    editableEmployee?.status === 'invited' ||
    editableEmployee?.status === 're-invited';

  useEffect(() => {
    fetchEmployees(pagination.page);
  }, [pagination.page, searchQuery]);

  // When status filter changes, fetch employees with the new status filter and
  // reset the page to be on page 1
  useEffect(() => {
    fetchEmployees(1);
  }, [statusFilter]);

  const handleSearchChange = (e: SyntheticEvent) => {
    setTimeout(() => {
      useEmployeesStore.setState({
        searchQuery: (e.target as HTMLInputElement).value,
      });
    }, 700);
  };

  const onAddEmployeeSuccess = () => {
    fetchEmployees(pagination.page);
    setShowAddEmployee(false);
  };

  const handleClickRow = (row: any) => {
    setEditableEmployee(row);
    setShowEditEmployee(true);
  };

  const handleCloseEditableModal = () => {
    setEditableEmployee(null);
    setEditable(false);
    setShowEditEmployee(false);
  };

  const handleClickCancel = () => {
    setEditable(false);
  };

  const handleSuccessfulEdit = () => {
    fetchEmployees(pagination.page);
    handleCloseEditableModal();
  };

  const handleClickReInvite = () => {
    setReinviteLoading(true);
    const resendableInvite: IInvite = {
      id: editableEmployee!.invite_id,
      first_name: editableEmployee!.first_name,
      last_name: editableEmployee!.last_name,
      email: editableEmployee!.email,
      role: editableEmployee!.role,
    };
    sdk
      .resendInvites([resendableInvite])
      .then(() => {
        setReinviteLoading(false);
        setReInviteStatusMessage('Re-invite sent successfully');
      })
      .catch(() => {
        setReinviteLoading(false);
        setReInviteStatusMessage('Failed to send re-invite');
      });
  };

  return (
    <>
      {/* Add Dialog */}
      <Dialog open={showAddEmployee} onOpenChange={setShowAddEmployee}>
        <DialogContent>
          <DialogTitle>Add Employee</DialogTitle>
          <DialogDescription className="hidden">Add Employee</DialogDescription>
          <AddEmployeeForm
            role="user"
            handleClose={() => setShowAddEmployee(false)}
            handleSuccess={onAddEmployeeSuccess}
          />
        </DialogContent>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={showEditEmployee} onOpenChange={handleCloseEditableModal}>
        <DialogContent className="max-w-screen-md">
          <DialogTitle>View Employee</DialogTitle>
          <DialogDescription className="hidden">
            View Employee
          </DialogDescription>
          {editable ? (
            <EditEmployeeForm
              employee={editableEmployee}
              handleClose={handleCloseEditableModal}
              handleCancel={handleClickCancel}
              handleSuccess={handleSuccessfulEdit}
            />
          ) : (
            <EmployeeDetail employee={editableEmployee} />
          )}

          <div>
            {!editable ? (
              <div className="flex gap-x-8 items-center">
                <div className="flex gap-x-2">
                  <Button onClick={() => setEditable(true)}>Edit</Button>
                  {showReInviteButton ? (
                    <Button
                      loading={reinviteLoading}
                      variant="outline"
                      onClick={handleClickReInvite}
                    >
                      Re-invite
                    </Button>
                  ) : null}
                </div>
                {reInviteStatusMessage ? (
                  <div className="text-success">{reInviteStatusMessage}</div>
                ) : null}
              </div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>

      <div className="text-sm max-w-screen-lg">
        <h1 className="text-2xl flex relative items-center font-semibold">
          <BiUser size={32} className="mr-2" />
          Employees
        </h1>
        <div className="flex justify-between items-center">
          <Link
            to="/dashboard"
            className="flex items-center mt-2 underline pl-10"
          >
            Back to Dashboard
          </Link>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="flex flex-auto gap-x-2">
            <Input
              className="max-w-[205px]"
              placeholder="Search by name or email"
              onChange={handleSearchChange}
            />
            <div className="max-w-[150px] w-full">
              <Select
                onValueChange={(value) => {
                  const v = value === 'none' ? '' : value;
                  useEmployeesStore.setState({
                    statusFilter: v as EmployeeStatus,
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Filter by Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Filter by Status</SelectLabel>
                    <SelectItem value="none">no status filter</SelectItem>
                    <SelectItem value="registered">registered</SelectItem>
                    <SelectItem value="pre-invitation">
                      pre-invitation
                    </SelectItem>
                    <SelectItem value="re-invited">re-invited</SelectItem>
                    <SelectItem value="invited">Invited</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <Button onClick={() => setShowAddEmployee(true)} variant="outline">
              Add Employee
            </Button>
          </div>
        </div>

        <Card className="flex flex-wrap gap-x-8 mt-4">
          <DataTable
            onPageChange={handlePageChange}
            pagination={pagination}
            columns={columns}
            data={employees}
            rowClickCallback={handleClickRow}
          />
        </Card>
      </div>
    </>
  );
};

export default Employees;
