import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { BiChevronLeft } from 'react-icons/bi';

export default function AccountLayout({ children }: { children: ReactNode }) {
  return (
    <div className="mt-4">
      <h2 className="font-bold">Account</h2>
      <Link
        className="ml-[-5px] md:ml-[-25px] underline text-xs flex items-center"
        to="/app/account"
      >
        <BiChevronLeft size={24} />
        Back to Overview
      </Link>
      <div className="mt-4">{children}</div>
    </div>
  );
}
