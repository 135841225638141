import { IUser } from '@/types.ts';
import { create } from 'zustand/index';
import { sdk } from '@/api.ts';

export interface AdministratorState {
  administrators: IUser[];
  pagination: {
    page: number;
    total_pages?: number;
  };
  searchQuery: string;
  handlePageChange: (page: number) => void;
  fetchAdmins: (page?: number) => void;
}

// Creating a zustand state for administrator data as this will need to be
// updated from a deeply nested component within the table
export const useAdministratorStore = create<AdministratorState>((set, get) => ({
  administrators: [],
  pagination: {
    page: 1,
    total_pages: 1,
  },
  searchQuery: '',
  handlePageChange: (newPage: number) =>
    set((state: Partial<AdministratorState>) => ({
      pagination: { ...state.pagination, page: newPage },
    })),
  fetchAdmins: async (page?: number) => {
    try {
      const response = await sdk.getEmployees({
        params: {
          role: 'alladmins',
          page: page || get().pagination.page,
          search: get().searchQuery,
        },
      });
      set({
        administrators: response.data.employees,
        pagination: {
          ...response.data.pagination,
          page: page || get().pagination.page,
        },
      });
    } catch (error) {
      console.error('Error fetching administrators: ', error);
    }
  },
}));
