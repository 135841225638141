import { BiChevronLeft, BiUser } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Card } from '@/components/ui/card.tsx';
import { useEffect, useState } from 'react';
import { DataTable } from '@/components/data-table.tsx';
import { columns } from '@/routes/dashboard/administrators/columns.tsx';
import { useAdministratorStore } from '@/routes/dashboard/administrators/store.ts';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { IEmployee, IInvite } from '@/types.ts';
import { sdk } from '@/api.ts';
import AddAdministratorForm from '@/routes/dashboard/administrators/add-administrator-form.tsx';
import EditAdministratorForm from '@/routes/dashboard/administrators/edit-administrator-form.tsx';
import AdministratorDetail from '@/routes/dashboard/administrators/administrator-detail.tsx';

export default function Administrators() {
  const { administrators, pagination, handlePageChange, fetchAdmins } =
    useAdministratorStore();

  const [reInviteStatusMessage, setReInviteStatusMessage] = useState('');
  const [reinviteLoading, setReinviteLoading] = useState(false);
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [showAddEmployee, setShowAddAdministrator] = useState(false);
  const [showEditEmployee, setShowEditEmployee] = useState(false);
  const [editableEmployee, setEditableEmployee] = useState<IEmployee | null>(
    null
  );

  const showReInviteButton =
    editableEmployee?.status === 'invited' ||
    editableEmployee?.status === 're-invited';

  const showRemoveAdminAccessButton =
    (editableEmployee?.role === 'admin' ||
      editableEmployee?.role === 'manager') &&
    editableEmployee?.status === 'registered';

  const showEditButton = editableEmployee?.status !== 'registered';

  useEffect(() => {
    fetchAdmins(pagination.page);
  }, [pagination.page]);

  const onAddAdministratorSuccess = () => {
    fetchAdmins(pagination.page);
    setShowAddAdministrator(false);
  };

  const handleClickRow = (row: any) => {
    setEditableEmployee(row);
    setShowEditEmployee(true);
  };

  const handleCloseEditableModal = () => {
    setEditableEmployee(null);
    setEditable(false);
    setShowEditEmployee(false);
  };

  const handleClickCancel = () => {
    setEditable(false);
  };

  const handleClickDeactivate = () => {
    setDeactivateLoading(true);
    sdk
      .downgradeAdmin(editableEmployee!.user_id)
      .then(() => {
        setDeactivateLoading(false);
        fetchAdmins(pagination.page);
        handleCloseEditableModal();
      })
      .catch(() => {
        setDeactivateLoading(false);
        console.error('Failed to deactivate employee');
      });
  };

  const handleSuccessfulEdit = () => {
    fetchAdmins(pagination.page);
    handleCloseEditableModal();
  };

  const handleClickReInvite = () => {
    setReinviteLoading(true);
    const resendableInvite: IInvite = {
      id: editableEmployee!.invite_id,
      first_name: editableEmployee!.first_name,
      last_name: editableEmployee!.last_name,
      email: editableEmployee!.email,
      role: editableEmployee!.role,
    };
    sdk
      .resendInvites([resendableInvite])
      .then(() => {
        setReinviteLoading(false);
        setReInviteStatusMessage('Re-invite sent successfully');
      })
      .catch(() => {
        setReinviteLoading(false);
        setReInviteStatusMessage('Failed to send re-invite');
      });
  };

  return (
    <>
      {/* Add Dialog */}
      <Dialog open={showAddEmployee} onOpenChange={setShowAddAdministrator}>
        <DialogContent>
          <DialogTitle>Add Administrator</DialogTitle>
          <DialogDescription className="hidden">
            Add Administrator
          </DialogDescription>
          <AddAdministratorForm
            handleClose={() => setShowAddAdministrator(false)}
            handleSuccess={onAddAdministratorSuccess}
          />
        </DialogContent>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={showEditEmployee} onOpenChange={handleCloseEditableModal}>
        <DialogContent className="max-w-screen-sm">
          <DialogTitle>Administrator</DialogTitle>
          <DialogDescription className="hidden">
            Administrator
          </DialogDescription>
          {editable ? (
            <EditAdministratorForm
              employee={editableEmployee}
              handleCancel={handleClickCancel}
              handleSuccess={handleSuccessfulEdit}
            />
          ) : (
            <AdministratorDetail employee={editableEmployee} />
          )}

          <div>
            {!editable ? (
              <div className="flex gap-x-8 items-center">
                <div className="flex gap-x-2">
                  {showEditButton ? (
                    <Button onClick={() => setEditable(true)}>Edit</Button>
                  ) : null}
                  {showReInviteButton ? (
                    <Button
                      loading={reinviteLoading}
                      variant="outline"
                      onClick={handleClickReInvite}
                    >
                      Re-invite
                    </Button>
                  ) : null}
                  {showRemoveAdminAccessButton ? (
                    <Button
                      loading={deactivateLoading}
                      variant="destructive"
                      onClick={handleClickDeactivate}
                    >
                      Remove Admin Access
                    </Button>
                  ) : null}
                </div>
                {reInviteStatusMessage ? (
                  <div className="text-success">{reInviteStatusMessage}</div>
                ) : null}
              </div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>

      <div className="text-sm">
        <h1 className="text-2xl flex relative items-center font-semibold">
          <BiUser size={32} className="mr-2" />
          Administrators
        </h1>
        <div className="flex justify-between items-center">
          <Link to="/dashboard" className="flex items-center mt-2 underline">
            <BiChevronLeft className="mr-2" size="24" />
            Back to Dashboard
          </Link>
        </div>
        <div className="flex justify-end items-center">
          <Button
            onClick={() => setShowAddAdministrator(true)}
            variant="outline"
          >
            Add Administrator
          </Button>
        </div>

        <Card className="mt-4">
          <DataTable
            onPageChange={handlePageChange}
            pagination={pagination}
            columns={columns}
            data={administrators}
            rowClickCallback={handleClickRow}
          />
        </Card>
      </div>
    </>
  );
}
