import { Button } from '@/components/ui/button.tsx';

export default function ChatRecommendation({
  name,
  description,
  ctaLabel = 'Continue',
  onClick,
}: {
  name: string;
  description: string;
  ctaLabel?: string;
  onClick: () => void;
}) {
  return (
    <div className="flex border border-black rounded items-center p-4">
      <div className="flex-1 ">
        <p className="font-bold">{name}</p>
        <p className="leading-5">{description}</p>
      </div>
      <Button onClick={onClick}>{ctaLabel}</Button>
    </div>
  );
}
