import { useEffect, useState } from 'react';
import React from 'react';
import { Button } from '@/components/ui/button';
import { IMessage } from '@/components/chat/types';
import {
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog';
import ChatResponse from '@/components/chat/chat-response.tsx';
import ChatInput from '@/components/chat/chat-input.tsx';
import ChatSuggestions from '@/components/chat/chat-suggestions';
import { AnimatePresence, motion } from 'framer-motion';
import { sdk } from '@/api.ts';
import DateSeparator from '@/components/chat/DateSeparator';
import Intercom from '@intercom/messenger-js-sdk';
import { useAuth } from '@/providers/auth';

//TODO: we need to revist auto-scrolling
const Chat = () => {
  const [userMessages, setUserMessages] = useState<IMessage[]>([]);
  const [botMessages, setBotMessages] = useState<IMessage[]>([]);
  const [messageFeed, setMessageFeed] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showProviderDemoModal, setShowProviderDemoModal] =
    useState<boolean>(false);
  const [fadeOutSuggestions, setFadeOutSuggestions] = useState<boolean>(false);
  const [messagesLoading, setMessagesLoading] = useState<boolean>(true);
  const baseURL = `${import.meta.env.VITE_API_BASE_URL}/api/v1`;
  const {user} = useAuth()

  useEffect(() => {
    if (user) {
      const full_name = user.first_name + " " + user.last_name
      const createdAt = typeof user.created_at === 'string'
        ? Math.floor(new Date(user.created_at).getTime() / 1000) 
        : user.created_at; 

      Intercom({
        app_id: 's1par89m', 
        user_id: user.id, 
        name: full_name,
        email: user.email, 
        created_at: createdAt, 
      });
    }
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [user]);

  // Combines user and bot messages into a single feed, sorted by timestamp
  const mergeMessages = () => {
    const feed = [...userMessages, ...botMessages];
    feed.sort((a, b) => {
      return (
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    });
    setMessageFeed(feed);
  };

  useEffect(() => {
    fetchAllMessages();
  }, []);

  useEffect(() => {
    mergeMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMessages, botMessages]);

  // Fetch all messages at once for the user
  const fetchAllMessages = async () => {
    try {
      const chatHistory = await sdk.getChatThreads();
      const messages: IMessage[] = chatHistory.thread_history;

      // Separate user and bot messages
      const userMessages = messages.filter((msg: IMessage) => msg.user_generated_message);
      const botMessages = messages.filter((msg: IMessage) => !msg.user_generated_message);

      setUserMessages(userMessages);
      setBotMessages(botMessages);

    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setMessagesLoading(false);
    }
  };


  function formatDate(date: Date) {
    // Check if the date is today
    const today = new Date();
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    if (isToday) {
      return 'Today';
    }

    // Include the year in the formatted date
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    const day = date.getDate();
    let daySuffix = 'th';

    if (day === 1 || day === 21 || day === 31) {
      daySuffix = 'st';
    } else if (day === 2 || day === 22) {
      daySuffix = 'nd';
    } else if (day === 3 || day === 23) {
      daySuffix = 'rd';
    }

    // Replace the day in the formatted date with the day and suffix
    const dayRegex = new RegExp(`\\b${day}\\b`);
    const formattedDateWithSuffix = formattedDate.replace(
      dayRegex,
      `${day}${daySuffix}`
    );

    return formattedDateWithSuffix;
  }

  const addUserMessage = (message: string) => {
    setUserMessages([
      ...userMessages,
      {
        message_content: message,
        created_at: new Date().toISOString(),
        user_generated_message: true,
      },
    ]);
  };

  const handleSubmit = async (text: string) => {
    setFadeOutSuggestions(true);
    // If the chatbot is still "thinking", don't submit anything
    if (loading) return;

    addUserMessage(text);
    setLoading(true);
    try {
      //we are using fetch because axios doesn't support the streaming
      const url = baseURL + '/chat/messages';
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': localStorage.getItem('csrf_access_token') || '',
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify({ message: text })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (!response.body) {
        throw new Error('ReadableStream not supported in this browser.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let botMessage = '';
      // Create a placeholder bot message
      setBotMessages(prevMessages => [
        ...prevMessages,
        {
          message_content: '',
          created_at: new Date().toISOString(),
          user_generated_message: false,
        }
      ]);

      const readChunk = async () => {
        const { value, done } = await reader.read();
        if (done) {
          setLoading(false);
          return;
        }

        const chunk = decoder.decode(value);
        botMessage += chunk;
        setBotMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          // Update the last bot message
          updatedMessages[updatedMessages.length - 1].message_content = botMessage;
          return updatedMessages;
        });


        // Keep reading
        await readChunk();
      };

      await readChunk();

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleClickSuggestion = async (suggestionText: string) => {
    await handleSubmit(suggestionText);
  };

  return (
    <div className="px-4 md:px-0">
      <div>
        <Dialog
          open={showProviderDemoModal}
          onOpenChange={setShowProviderDemoModal}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="sr-only">
                This is a demo modal and should be removed when the product is
                shipped.
              </DialogTitle>
              <DialogDescription className="pt-4">
                Employees will have the ability to access a curated set of
                benefits personalized to their financial needs. Peopled will
                offer these solutions at a discount versus regular pricing.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button onClick={() => setShowProviderDemoModal(false)}>
                Continue
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      <div
        test-id="chat-message-feed"
        className="flex h-full flex-col justify-end pb-1"
      >
        {!messagesLoading && messageFeed.length === 0 && (
          <div test-id="chat-suggestions">
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 1 }}
                animate={{
                  opacity: fadeOutSuggestions ? 0 : 1,
                  display: fadeOutSuggestions ? 'none' : 'block',
                  visibility: fadeOutSuggestions ? 'hidden' : 'visible',
                }}
                transition={{ duration: 0.5, delay: 0 }}
                exit={{ opacity: 0 }}
              >
                <ChatSuggestions onClickSuggestion={handleClickSuggestion} />
              </motion.div>
            </AnimatePresence>
          </div>
        )}

        {/* Initialize lastDateString */}
        {(() => {
          let lastDateString = '';

          return messageFeed.map((item, index) => {
            const isUser = item.user_generated_message;
            const messageDate = new Date(item.created_at);
            const messageDateStr = messageDate.toISOString().split('T')[0];
            const formattedDateString = formatDate(messageDate);

            let dateSeparator = null;

            if (index === 0 || lastDateString !== messageDateStr) {
              const testId = `date-separator-${messageDateStr}`;
              dateSeparator = (
                <DateSeparator date={formattedDateString} testId={testId} />
              );
              lastDateString = messageDateStr;
            }

            return (
              <React.Fragment key={`msg-${index}`}>
                {dateSeparator}
                <div
                  className={`chat-message py-1 ${
                    isUser ? 'flex justify-end' : ''
                  }`}
                >
                  <div className={`flex ${isUser ? 'max-w-[60%]' : ''}`}>
                    <ChatResponse
                      type={isUser ? 'user' : 'bot'}
                      message={item.message_content}
                      onClickRecommendation={() => setShowProviderDemoModal(true)}
                      loading={loading && index === messageFeed.length - 1 && !isUser}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          });
        })()}
      </div>
      <div className="sticky bottom-0 rounded-xl bg-app-background pb-4">
        <ChatInput onSubmit={handleSubmit} loading={loading} />
      </div>
    </div>
  );
};

export default Chat;