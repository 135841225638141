import { IOrganization } from '@/types.ts';
import { NavLink } from 'react-router-dom';
import { ReactNode } from 'react';
import employees from '@/assets/icons/employees.svg';
import dashboard from '@/assets/icons/dashboard.svg';
import business from '@/assets/icons/business.svg';
import admin from '@/assets/icons/admin.svg';
import builder from '@/assets/icons/builder.svg';

interface SidebarProps {
  organization: IOrganization;
}

const MenuLink = ({
  children,
  icon,
  to,
}: {
  children: ReactNode;
  icon?: string;
  to: string;
}) => {
  return (
    <NavLink
      end
      to={to}
      className={({ isActive }) =>
        isActive
          ? 'flex rounded bg-accent'
          : 'flex hover:rounded hover:bg-accent'
      }
    >
      <div className="p-2.5 flex gap-x-2 items-center text-base font-medium">
        <img src={icon} alt="icon" className="w-5 h-5" />
        {children}
      </div>
    </NavLink>
  );
};

const Sidebar = ({ organization }: SidebarProps) => {
  return (
    <aside className="py-8 pl-6 pr-9 border-0 border-r-2 min-w-[248px] max-w-[248px]">
      <h3 className="mt-12 mb-5 text-lg font-bold">{organization?.name}</h3>
      <MenuLink to="/dashboard" icon={dashboard}>
        Dashboard
      </MenuLink>

      <p className="text-sm font-semibold mt-12 mb-2.5">Manage</p>
      <MenuLink to="/dashboard/organization" icon={business}>
        Company Info
      </MenuLink>

      <MenuLink to="/dashboard/employees" icon={employees}>
        Employees
      </MenuLink>

      <MenuLink to="/dashboard/administrators" icon={admin}>
        Admin Users
      </MenuLink>

      <MenuLink to="/dashboard/experience" icon={builder}>
        Experience
      </MenuLink>
    </aside>
  );
};

export default Sidebar;
