import AccountLayout from '@/routes/account/layout.tsx';
import { BiChat, BiDownload, BiTrash } from 'react-icons/bi';
import { sdk } from '@/api.ts';
import { useEffect, useState } from 'react';
import { useAuth } from '@/providers/auth.tsx';
import { IThread } from '@/components/chat/types.ts';
import { Button } from '@/components/ui/button.tsx';
import { Card } from '@/components/ui/card.tsx';
import { Separator } from '@/components/ui/separator.tsx';

const initialState: IThread = {
  conversation_statistics: {
    conversation_last_updated: '',
    conversation_created_at: '',
    conversation_length: 0,
  },
  page: 1,
  per_page: 10,
  total_pages: 1,
  total: 0,
  thread_history: [],
};

export default function AccountChatHistory() {
  const { user } = useAuth();
  const [chatHistory, setChatHistory] = useState<IThread>(initialState);

  useEffect(() => {
    fetchThreads();
  }, []);

  const { thread_history, conversation_statistics } = chatHistory;
  const {
    conversation_last_updated,
    conversation_created_at,
    conversation_length,
  } = conversation_statistics;

  const fetchThreads = async () => {
    const result = await sdk.getChatThreads();
    setChatHistory(result);
  };

  const handleClickDownload = async () => {
    await sdk.downloadChatThread();
  };
  const handleClickDelete = async () => {
    await sdk.forgetChatThread();
    await fetchThreads();
  };

  return (
    <AccountLayout>
      <Card>
        <div className="flex justify-between">
          <div className="flex items-center">
            <BiChat size={24} />
            <h3 className="text-sm font-bold ml-2">Chat History</h3>
          </div>
          <div className="flex gap-x-4">
            <Button variant="outline" onClick={handleClickDownload}>
              <BiDownload className="mr-1" />
              Download
            </Button>
            <Button variant="outline" onClick={handleClickDelete}>
              <BiTrash className="mr-1" />
              Delete
            </Button>
          </div>
        </div>
        <Separator />
        <div className="flex justify-between text-sm mt-4">
          <div>
            <p className="font-bold">Last Chat</p>
            <p test-id="chat-last-updated">
              {conversation_last_updated || 'N/A'}
            </p>
          </div>
          <div>
            <p className="font-bold">Started</p>
            <p test-id="chat-created-at">{conversation_created_at || 'N/A'}</p>
          </div>
          <div>
            <p className="font-bold">Total Messages</p>
            <p test-id="chat-conversation-length">{conversation_length}</p>
          </div>
        </div>
        <div
          test-id="chat-history-feed"
          className="mt-8 max-h-[500px] overflow-y-auto"
        >
          {thread_history.map((message, index) => {
            const isUser = message.user_generated_message;
            return (
              <div key={index} className="mb-4">
                <div className={`font-bold ${isUser ? '' : 'uppercase'}`}>
                  {isUser ? user.first_name : 'Peopled'}
                </div>
                <div
                  className="markdown"
                  dangerouslySetInnerHTML={{ __html: message.message_content }}
                />
              </div>
            );
          })}
        </div>
      </Card>
    </AccountLayout>
  );
}
