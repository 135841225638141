import ProtectedRoute from '@/components/protected-route.tsx';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Login from '@/routes/login';
import App from '@/routes/app.tsx';
import Account from '@/routes/account';
import AccountPersonal from '@/routes/account/personal';
import AccountContact from '@/routes/account/contact';
import AccountChatHistory from '@/routes/account/chat-history';
import Invite from '@/routes/invite';
import Files from '@/routes/files';
import Profile from '@/routes/account/profile';
import Dashboard from '@/routes/dashboard';
import Invites from '@/routes/dashboard/invites';
import Organization from '@/routes/dashboard/organization';
import Administrators from '@/routes/dashboard/administrators';
import Employees from '@/routes/dashboard/employees';
import ExperienceSwitcher from '@/routes/experience-switcher';
import Census from '@/routes/dashboard/census';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/app" />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/invite',
    element: <Invite />,
  },
  {
    path: '/app',
    element: <ProtectedRoute element={<App />} />,
  },
  {
    path: '/experience-switcher',
    element: <ProtectedRoute element={<ExperienceSwitcher />} />,
  },
  {
    path: '/dashboard',
    element: <ProtectedRoute element={<Dashboard />} />,
    children: [
      {
        path: 'organization',
        element: <Organization />,
      },
      {
        path: 'administrators',
        element: <Administrators />,
      },
      {
        path: 'invites',
        element: <Invites />,
      },
      {
        path: 'employees',
        element: <Employees />,
      },
      {
        path: 'census',
        element: <Census />,
      },
      {
        path: 'participants/invites',
        element: <Invites />,
      },
    ],
  },
  {
    path: '/files/:organizationId/documents/:documentId',
    element: <ProtectedRoute element={<Files />} />,
  },
  {
    path: '/app/account',
    element: <ProtectedRoute element={<Account />} />,
    children: [
      {
        path: 'personal',
        element: <AccountPersonal />,
      },
      {
        path: 'contact',
        element: <AccountContact />,
      },
      {
        path: 'chat-history',
        element: <AccountChatHistory />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
]);

export default router;
