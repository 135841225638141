// If an employee is registered, show the correct form vs an invited employee
import { IEmployee } from '@/types.ts';
import EditRegisteredEmployeeForm from '@/routes/dashboard/employees/edit-registered-employee-form.tsx';
import EditInvitedEmployeeForm from '@/routes/dashboard/employees/edit-invited-employee-form.tsx';

const EditEmployeeForm = ({
  employee,
  handleClose,
  handleCancel,
  handleSuccess,
}: {
  employee: IEmployee | null;
  handleClose: () => void;
  handleCancel: () => void;
  handleSuccess: () => void;
}) => {
  const registered = employee?.status === 'registered';

  return registered ? (
    <EditRegisteredEmployeeForm
      employee={employee}
      handleCancel={handleCancel}
      handleSuccess={handleSuccess}
    />
  ) : (
    <EditInvitedEmployeeForm
      employee={employee}
      handleCancel={handleCancel}
      handleClose={handleClose}
      handleSuccess={handleSuccess}
    />
  );
};

export default EditEmployeeForm;
