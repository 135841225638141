import Chat from '@/components/chat';
import Header from '@/components/header';
import { useAuth } from '@/providers/auth.tsx';
import { Navigate } from 'react-router-dom';

function App() {
  const { user } = useAuth();
  // @TODO maybe make a hook or utility for serializing/deserializing a full userPreferences object for localStorage
  // @TODO or even consider a server-side preference object if the need for more preferences arises
  const experiencePreference = localStorage.getItem('experiencePreference');
  if (user.role === 'admin') {
    if (!experiencePreference) return <Navigate to="/experience-switcher" />;
    if (experiencePreference === 'dashboard')
      return <Navigate to="/dashboard" />;
  }

  return (
    <div className="bg-app-background h-full overflow-auto flex flex-col justify-between">
      <Header />
      <div className="w-full max-w-[640px] w-screen-sm mx-auto">
        <Chat />
      </div>
    </div>
  );
}

export default App;
