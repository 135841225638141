import { getTheme } from '@/lib/theme.ts';

interface DynamicFaceProps {
  color?: string;
}

const DynamicFace = ({ color = '#EC6D53' }: DynamicFaceProps) => {
  const theme = getTheme();
  if (theme.colors && theme.colors['icon-face']) {
    color = theme.colors['icon-face'];
  }

  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 14.4543C30 17.1812 29.3437 19.6367 28.04 21.8179C26.7275 23.9991 24.9371 25.729 22.6615 27.0003C20.3947 28.2789 17.8375 28.9101 15 28.9101C12.1625 28.9101 9.62147 28.2789 7.36358 27.0003C5.11307 25.7305 3.32416 24.0006 1.9939 21.8179C0.66365 19.6367 0 17.1812 0 14.4543C0 11.7275 0.665125 9.27195 1.9939 7.09075C3.32416 4.90955 5.11307 3.187 7.36358 1.90837C9.62147 0.63858 12.1625 0 15 0C17.8375 0 20.3947 0.63858 22.6615 1.90837C24.9371 3.187 26.7275 4.90807 28.04 7.09075C29.3437 9.27195 30 11.7275 30 14.4543Z"
        fill={color}
      />
      <path
        d="M5.71179 10.0123C5.71179 10.0123 5.71179 12.1537 8.06259 12.1537C10.4134 12.1537 10.3603 10.0123 10.3603 10.0123"
        stroke="#31214C"
        strokeWidth="0.589912"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.3228 10.0123C17.3228 10.0123 17.3228 12.1537 19.6736 12.1537C22.0244 12.1537 21.9713 10.0123 21.9713 10.0123"
        stroke="#31214C"
        strokeWidth="0.589912"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.844 24.356C11.844 24.356 14.7065 25.9694 18.4068 24.6878C21.6675 23.5582 22.8046 20.7251 22.8046 20.7251"
        stroke="#31214C"
        strokeWidth="0.589912"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.2789 7.35916C13.2789 7.35916 10.9237 17.5337 10.7865 17.9776C10.6494 18.4215 10.9237 19.65 12.9722 19.0026C15.0206 18.3537 15.2581 17.1252 14.7817 16.8509C14.3054 16.578 13.3158 16.6341 13.5871 17.6015C13.8585 18.569 14.7817 19.4715 15.8067 19.1515C16.8317 18.8315 16.7624 17.9097 16.7624 17.9097"
        stroke="#31214C"
        strokeWidth="0.589912"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DynamicFace;
