import { HTMLAttributes, ReactNode } from 'react';
import bookSearch from '@/assets/icons/book-search.svg';
import investmentSelection from '@/assets/icons/investment_selection.svg';
import medical from '@/assets/icons/medical.svg';

interface ISuggestionProps extends HTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  handleClick: (_text: string) => void;
  variant: 'book-search' | 'investment_selection' | 'medical';
}

export default function ChatSuggestion({
  children,
  variant,
  handleClick,
}: ISuggestionProps) {
  let icon = null;
  switch (variant) {
    case 'book-search':
      icon = bookSearch;
      break;
    case 'investment_selection':
      icon = investmentSelection;
      break;
    case 'medical':
      icon = medical;
      break;
  }
  return (
    <button
      onClick={() => handleClick(children!.toString())}
      className="p-4 bg-background border rounded chat-suggestion"
    >
      <div>
        <img width={24} src={icon} alt="icon" />
      </div>
      <div className="text-left">
        <span className="text-sm font-medium">{children}</span>
      </div>
    </button>
  );
}
