import logo from '@/assets/images/logo/primary-orange.svg';
import { Button } from '@/components/ui/button.tsx';
import { useAuth } from '@/providers/auth.tsx';
import { Link, useNavigate } from 'react-router-dom';
import { SyntheticEvent } from 'react';
import { Avatar, AvatarFallback } from '@/components/ui/avatar.tsx';
import { getTheme } from '@/lib/theme.ts';

export default function Header() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const theme = getTheme();

  const getInitials = () => {
    if (user && user.first_name) {
      return user.first_name[0] + user.last_name[0];
    }
    return '';
  };

  const doLogout = async (e: SyntheticEvent) => {
    e.preventDefault();
    logout();
    navigate('/');
  };

  return (
    <header className="border-outline sticky top-0 border border-b-2 bg-white py-2 pr-4 z-[1]">
      <div className="max-w-screen-lg mx-auto flex justify-between">
        <div className="flex-1 pl-4">
          <Link to="/">
            <img
              src={theme.logoUrl ? theme.logoUrl : logo}
              className="max-w-[133px]"
              alt="Logo"
            />
          </Link>
        </div>
        <Link to="/app/account" className="mr-2">
          <Avatar>
            <AvatarFallback>{getInitials()}</AvatarFallback>
          </Avatar>
        </Link>
        <Button onClick={doLogout}>Sign Out</Button>
      </div>
    </header>
  );
}
