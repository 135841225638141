import Header from '@/components/header';
import { ReactNode, useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { IMetrics, IOrganization } from '@/types.ts';
import { Card } from '@/components/ui/card.tsx';
import { BiChevronRight } from 'react-icons/bi';
import faceYellow from '@/assets/images/logo/face-yellow.svg';
import { useAuth } from '@/providers/auth.tsx';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Sidebar from '@/routes/dashboard/sidebar.tsx';
import Intercom from '@intercom/messenger-js-sdk'; // Added Intercom import

const ControlPanelCard = ({
  children,
  linkTo = '#',
  title,
}: {
  children: ReactNode;
  linkTo?: string;
  title: string;
}) => {
  return (
    <Card className="w-[200px]">
      <Link to={linkTo}>
        <h4 className="text-sm font-semibold flex items-center underline">
          {title} <BiChevronRight size={20} />
        </h4>
      </Link>
      <div className="text-xs mt-4">
        <span className="font-bold">Quick Links</span>
        <div className="[&>*]:underline flex flex-col">{children}</div>
      </div>
    </Card>
  );
};

const MetricsBlock = ({ metrics }: { metrics: IMetrics | null }) => {
  let adoptionPercentage = '-';

  if (metrics?.total_employees && metrics?.total_users) {
    const num = (metrics.total_users / metrics.total_employees) * 100;
    if (num === 0) {
      adoptionPercentage = '-';
    }
    adoptionPercentage = num.toFixed(2) + '%';
  }

  return (
    <div className="grid grid-cols-2 grid-rows-3 gap-2 p-4">
      <p>
        <span className="font-semibold text-base mr-1">
          {metrics?.total_users || '-'}
        </span>
        Employee Accounts
      </p>
      <p>
        <span className="font-semibold text-base mr-1">
          {metrics?.total_employees || '-'}
        </span>
        Total Employees
      </p>
      <p>
        <span className="font-semibold text-base mr-1">
          {adoptionPercentage}
        </span>
        Employee Adoption
      </p>
      <p>
        <span className="font-semibold text-base mr-1">
          {metrics?.total_chat_users || '-'}
        </span>
        Employees have used the chat
      </p>
      <p>
        <span className="font-semibold text-base mr-1">
          {metrics?.total_chat_sessions || '-'}
        </span>
        Total chat sessions
      </p>
      <p>
        <span className="font-semibold text-base mr-1">
          {metrics?.employer_hours_saved || '-'}
        </span>
        Employer hours saved with chat
      </p>
    </div>
  );
};

const Dashboard = () => {
  const { user } = useAuth();
  const location = useLocation();
  const isBaseRoute = location.pathname === '/dashboard';
  const [organization, setOrganization] = useState<IOrganization | null>(null);
  const [metrics, setMetrics] = useState<IMetrics | null>(null);

  useEffect(() => {
    sdk.getMetrics().then((response) => setMetrics(response.data));
    sdk
      .getOrganization(user.organization_id)
      .then((response) => setOrganization(response.data))
      .catch((error) => console.error(error));
  }, []);

  // Initialize Intercom widget
  useEffect(() => {
    if (user) {
      const full_name = user.first_name + ' ' + user.last_name;
      const createdAt =
        typeof user.created_at === 'string'
          ? Math.floor(new Date(user.created_at).getTime() / 1000)
          : user.created_at;

      Intercom({
        app_id: 's1par89m',
        user_id: user.id,
        name: full_name,
        email: user.email,
        created_at: createdAt,
      });
    }
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [user]);

  if (!organization) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex flex-col h-screen">
      <Header />

      <div className="flex flex-1">
        <Sidebar organization={organization} />
        <main className="flex-1 p-8 bg-app-background">
          {isBaseRoute && (
            <div className="max-w-screen-md">
              <div className="flex text-sm">
                <div className="p-4 border-0 border-r border-dark flex w-1/3">
                  <div className="px-4 pt-6 max-w-[70px]">
                    <img src={faceYellow} alt="" />
                  </div>
                  <div>
                    <h3 className="font-semibold mb-2 underline">To Do's</h3>
                    <p className="text-xs">
                      You have no action items needing your attention
                    </p>
                  </div>
                </div>
                <div className="w-2/3">
                  <MetricsBlock metrics={metrics} />
                </div>
              </div>
              <h1 className="text-2xl font-semibold">Control Panel</h1>
              <div className="flex flex-wrap gap-4 mt-4">
                <ControlPanelCard
                  title="Employees"
                  linkTo="/dashboard/employees"
                >
                  <Link to="/dashboard/employees">Manage employee users</Link>
                  <Link to="/dashboard/census">Upload employee census</Link>
                  <Link className="mt-4" to="/dashboard/employees">
                    See all
                  </Link>
                </ControlPanelCard>
                <ControlPanelCard
                  title="Company Info"
                  linkTo="/dashboard/organization"
                >
                  <Link to="/dashboard/organization">
                    Tell us about your organization
                  </Link>
                  <Link className="mt-4" to="/dashboard/organization">
                    See all
                  </Link>
                </ControlPanelCard>
                <ControlPanelCard
                  title="Manage Admins"
                  linkTo="/dashboard/administrators"
                >
                  <Link to="/dashboard/administrators">
                    Set up new admins for organization
                  </Link>
                  <Link to="/dashboard/administrators">Manage admin users</Link>
                  <Link className="mt-4" to="/dashboard/administrators">
                    See all
                  </Link>
                </ControlPanelCard>
                <ControlPanelCard title="Reporting">
                  <Link to="#">Create new report</Link>
                  <Link to="#">See usage metrics</Link>
                  <Link to="#">See service reports</Link>
                  <Link className="mt-4" to="#">
                    See all
                  </Link>
                </ControlPanelCard>
              </div>
            </div>
          )}
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
