import { IEmployee } from '@/types.ts';
import { format } from 'date-fns';

interface AdministratorDetailProps {
  employee: IEmployee | null;
}

const AdministratorDetail = ({ employee }: AdministratorDetailProps) => {
  if (!employee) return <div></div>;

  const formatDateIfExists = (dateString: string) => {
    if (dateString) {
      return format(new Date(dateString), 'MM/dd/yy');
    }
    return '-';
  };

  format(new Date(employee?.last_login_timestamp), 'MM/dd/yy');
  return (
    <div className="flex">
      <div className="flex-1">
        <dl className="detail-dl">
          <dt>First Name</dt>
          <dd>{employee?.first_name}</dd>
          <dt>Last Name</dt>
          <dd>{employee?.last_name}</dd>
          <dt className="mt-4">Email Address</dt>
          <dd>{employee?.email}</dd>
        </dl>
      </div>
      <div className="flex-1">
        <dl className="detail-dl">
          <dt>Invitation Date</dt>
          <dd>{formatDateIfExists(employee?.invite_created_at)}</dd>
          <dt>Registration Date</dt>
          <dd>{formatDateIfExists(employee?.created_at)}</dd>
          <dt>Last Login</dt>
          <dd>{formatDateIfExists(employee?.last_login_timestamp)}</dd>
        </dl>
      </div>
    </div>
  );
};

export default AdministratorDetail;
