import { IInvite } from '@/types.ts';
import { create } from 'zustand/index';
import { sdk } from '@/api.ts';

export interface InvitesState {
  invites: IInvite[];
  pagination: {
    page: number;
    total_pages?: number;
  };
  searchQuery: string;
  handlePageChange: (page: number) => void;
  fetchInvites: (page?: number) => void;
}

export const useInvitesStore = create<InvitesState>((set, get) => ({
  invites: [],
  pagination: {
    page: 1,
    total_pages: 1,
  },
  searchQuery: '',
  handlePageChange: (newPage: number) =>
    set((state: Partial<InvitesState>) => ({
      pagination: { ...state.pagination, page: newPage },
    })),
  fetchInvites: async (page?: number) => {
    try {
      const response = await sdk.getInvites({
        params: {
          page: page || get().pagination.page,
          search: get().searchQuery,
        },
      });

      set({
        invites: response.data.invites,
        pagination: {
          ...get().pagination,
          page: page || get().pagination.page,
        },
      });
    } catch (error) {
      console.error('Error fetching invites: ', error);
    }
  },
}));
