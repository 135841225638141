export interface DateSeparatorProps {
  date: string;
  testId: string;
}

const DateSeparator = ({ date, testId }: DateSeparatorProps): JSX.Element => {
  return (
    <div className="flex items-center my-4" data-testid={testId}>
      <div
        className="flex-grow border-t"
        style={{ borderColor: 'var(--System-foreground, #0A0A0A)' }}
      ></div>
      <span
        className="mx-4"
        style={{
          color: 'var(--System-foreground, #0A0A0A)',
          fontFamily: 'Montserrat',
          fontSize: 'var(--font-size-text-sm, 14px)',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
        }}
      >
        {date}
      </span>
      <div
        className="flex-grow border-t"
        style={{ borderColor: 'var(--System-foreground, #0A0A0A)' }}
      ></div>
    </div>
  );
};

export default DateSeparator;