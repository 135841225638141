import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { IInvite } from '@/types.ts';
import ResendInvite from '@/routes/dashboard/invites/resend-invite.tsx';

export const columns: ColumnDef<IInvite>[] = [
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'first_name',
    header: 'First Name',
  },
  {
    accessorKey: 'last_name',
    header: 'Last Name',
  },
  {
    accessorKey: 'role',
    header: 'Role',
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    cell: ({ row }) => {
      const date: string = row.getValue('created_at');
      return format(new Date(date), 'MM/dd/yy');
    },
  },
  {
    accessorKey: 'status_accepted',
    header: 'Status',
    cell: ({ row }) => {
      const status: boolean = row.getValue('status_accepted');
      return status ? 'Accepted' : 'Pending';
    },
  },
  {
    accessorKey: 'expires_at',
    header: 'Invite Expiry',
    cell: ({ row }) => {
      const date: string = row.getValue('expires_at');
      return format(new Date(date), 'MM/dd/yy');
    },
  },
  {
    accessorKey: '',
    header: 'Re-send Invite',
    cell: ({ row }) => {
      const accepted: boolean = row.getValue('status_accepted');
      if (!accepted) {
        return <ResendInvite invite={row.original} />;
      }
    },
  },
];
