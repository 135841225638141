import { IUser } from '@/types.ts';
import { create } from 'zustand';
import { sdk } from '@/api.ts';

export type EmployeeStatus =
  | 'registered'
  | 'pre-invitation'
  | 're-invited'
  | 'invited';

export type EmployeeRole = 'user' | 'admin';

export interface EmployeesStore {
  employees: IUser[];
  statusFilter: EmployeeStatus | null;
  pagination: {
    page: number;
    total_pages?: number;
  };
  searchQuery: string;
  handlePageChange: (page: number) => void;
  fetchEmployees: (page?: number) => void;
}

export const useEmployeesStore = create<EmployeesStore>((set, get) => ({
  employees: [],
  statusFilter: null,
  pagination: {
    page: 1,
    total_pages: 1,
  },
  searchQuery: '',
  handlePageChange: (newPage: number) =>
    set((state: Partial<EmployeesStore>) => ({
      pagination: { ...state.pagination, page: newPage },
    })),
  fetchEmployees: async (page?: number) => {
    try {
      const response = await sdk.getEmployees({
        params: {
          role: 'allusers',
          page: page || get().pagination.page,
          ...(get().searchQuery ? { search: get().searchQuery } : {}),
          ...(get().statusFilter ? { status: get().statusFilter } : {}),
        },
      });
      set({
        employees: response.data.employees,
        pagination: {
          ...response.data.pagination,
          page: page || get().pagination.page,
        },
      });
    } catch (error) {
      console.error('Error fetching employees: ', error);
    }
  },
}));
