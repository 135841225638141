import AccountLayout from '@/routes/account/layout.tsx';
import { BiEnvelope } from 'react-icons/bi';
import { useAuth } from '@/providers/auth.tsx';
import { Card } from '@/components/ui/card.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useState } from 'react';
import AccountContactDetails from '@/routes/account/contact/details.tsx';
import EditContactForm from '@/routes/account/contact/edit-form.tsx';

export default function AccountContact() {
  const [editable, setEditable] = useState(false);
  const { user } = useAuth();
  return (
    <AccountLayout>
      <Card className="mb-8">
        <div className="flex justify-between">
          <div className="flex items-center">
            <BiEnvelope size={24} />
            <h3 className="text-sm font-bold ml-2">Contact Information</h3>
          </div>
          <Button
            variant={editable ? 'outline' : 'default'}
            onClick={() => setEditable(!editable)}
          >
            {editable ? 'Cancel' : 'Edit'}
          </Button>
        </div>
        {editable ? (
          <EditContactForm user={user} />
        ) : (
          <AccountContactDetails user={user} />
        )}
      </Card>
    </AccountLayout>
  );
}
